import React from 'react'
import {useSelector} from 'react-redux'
import {AppState} from '../../app/app-store'

export const FireLogo: React.FC<{ staticId?: string | undefined }> = ({ staticId }) => {
    const stateTenantId = useSelector((state: AppState) => state.appData.tenantId)
    const tenantData = useSelector((state: AppState) => state.appData.tenantData)
    //use tenant ID provided by url for open pages
    const tenantId = staticId || stateTenantId
    const { name, logo, formsLogoUrl, isProduction } = tenantData || {}
    const has_refferrer = !!document.referrer && document.referrer !== document.location.href
    const url = has_refferrer ? document.referrer : formsLogoUrl || '/'

    const opacity = (isProduction) ? 1 : 0.4

    if (!tenantData)
        return null

    return (
        <div style={{ position: 'relative'}}>
            <img src={logo} style={{ height: '64px', opacity }}/>
            { !isProduction &&
                <div style={{
                  position: 'absolute',
                  top: '10px',
                  left: '0px',
                  background: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '3px',
                  border: '1px dashed #B70000',
                  color: '#B70000',
                  padding: '6px 6px',
                  transform: 'rotate(-20deg)',
                  fontSize: '1.2em',
                  textDecoration: 'none'
                }}>TEST</div>
            }
        </div>
    )
}
